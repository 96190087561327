// JavaScript Document

// Scripts written by Jacob Bearce @ Weblinx, Inc.

import handorgel from "handorgel";

const UPDATE_VIA_HASH = (handorgel, hash) => {
    const TARGET_ID = hash.replace(/^#/, "");

    handorgel.folds.forEach((fold) => {
        if ((TARGET_ID.match(/^[A-Za-z]/) && fold.content.querySelector(`#${TARGET_ID}`)) || fold.header.dataset.id === TARGET_ID) {
            fold.open();
        }

        if (fold.header.dataset.id === TARGET_ID) {
            fold.header.classList.add("hash-target");
        } else if (hash.match(/^#[A-Za-z]/)  && fold.content.querySelector(hash)) {
            fold.content.querySelector(hash).classList.add("hash-target");
        }
    });
};

const PARAMS     = new URLSearchParams(window.location.search);
const HANDORGELS = document.querySelectorAll(".handorgel");

HANDORGELS.forEach((element) => {
    const HANDORGEL = new handorgel(element);

    /**
     * Open accordions that contain a submitted Gravity Form
     */
    HANDORGEL.folds.forEach((fold) => {
        if (fold.content.querySelector(".gform_confirmation_message") || fold.content.querySelector(".gform_validation_errors")) {
            fold.open();
            fold.header.classList.add("hash-target");
            fold.header.scrollIntoView();
        }
    });

    /**
     * Open accordions targeted by Relevanssi
     */
    if (PARAMS.get("highlight")) {
        HANDORGEL.folds.forEach((fold) => {
            if (fold.content.querySelector("mark")) {
                fold.open();
            }
        });
    }

    /**
     * Open accordions targeted by a URL hash on hashchange
     */
    window.addEventListener("hashchange", () => {
        UPDATE_VIA_HASH(HANDORGEL, window.location.hash);
    });

    /**
     * Open accordions targeted by a URL hash on load
     */
    if (window.location.hash) {
        UPDATE_VIA_HASH(HANDORGEL, window.location.hash);
    }

    /**
     * Scroll to hash when an accordion finishes opening
     */
    HANDORGEL.on("fold:opened", (fold) => {
        setTimeout(() => {
            if (window.location.hash && (fold.header.classList.contains("hash-target") || fold.content.querySelector(".hash-target") || (window.location.hash.match(/^#[A-Za-z]/) && (fold.header.querySelector(window.location.hash) || fold.content.querySelector(window.location.hash))))) {
                console.log(fold.header.classList);
                document.querySelector(".hash-target").scrollIntoView();
            }
        }, 250);
    });

    /**
     * Fix Firefox bug
     */
    HANDORGEL.on("fold:close", (fold) => {
        setTimeout(() => {
            fold.header.classList.remove("handorgel__header--open");
            fold.content.classList.remove("handorgel__content--open");
        }, 100);
    });
});
